import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import Loading from './components/loading/loading.js'


createApp(App).use(ElementPlus).use(store).use(router).use(Loading).mount('#app')
