import { createStore } from "vuex";

export default createStore({
  state: {
    scenicSpotList: [
      {
        id: 1,
        img: require("@/assets/img/jingdian/jd01.jpg"),
        title: "黄山+宏村3日2晚跟团游",
        price: 1299,
        bigImg: require("@/assets/img/jingdian/jd01-01.jpg"),
        desc: "VIP精致16人小团【绽放徽黄&一生想游】纯玩团+24h客服管家（送往返景交+登山三宝）西海大峡谷【ABC线1晚宿1600m山顶标间&推窗即景+1晚宿屯溪4钻酒店/DEF线精选市区4钻酒店连住&吃逛游老街游】",
        line: [
          {
            title: "A:去程高铁+山顶标间+市区酒店",
            price: 1675,
          },
          {
            title: "B:往返高铁+山顶标间+市区酒店",
            price: 1901,
          },
          {
            title: "C:人工出票+山顶标间+市区酒店",
            price: 1716,
          },
          {
            title: "D:去程高铁+市区4钻酒店连住",
            price: 1675,
          },
          {
            title: "E:往返高铁+市区4钻酒店连住",
            price: 1675,
          },
        ],
      },
      {
        id: 2,
        img: require("@/assets/img/jingdian/jd02.jpg"),
        title: "浙江普陀山+洛迦山4日3晚跟团游",
        price: 970,
        desc: "【2人出行立减100】赠3早 含上行缆车 登佛顶山俯瞰普陀山全景+游海上卧佛洛迦山【宿1晚普陀岛上海边客栈 可自行看日出日落 2晚宁波酒店 】 南海观音露天佛像 许愿还愿 为家人祈福",
        bigImg: require("@/assets/img/jingdian/jd02-01.jpg"),
        line: [
          {
            title: "A:往返双高",
            price: 1152,
          },
          {
            title: "B:往返双飞",
            price: 1229,
          },
          {
            title: "C:去程单高",
            price: 970,
          },
        ],
      },
      {
        id: 3,
        img: require("@/assets/img/jingdian/jd03.jpg"),
        title: "乌镇+苏州+南京3日2晚跟团游",
        price: 1066,
        desc: "苏州+乌镇+南京3日游 纯玩 全程不进购物店 拙政园+寒山寺+山塘街+乌镇+乌镇西栅+南京总统府+南京博物院 +中山陵+夫子庙",
        bigImg: require("@/assets/img/jingdian/jd03-01.jpg"),
        line: [
          {
            title: "A:往返双高",
            price: 1189,
          },
          {
            title: "B:往返双飞",
            price: 1199,
          },
          {
            title: "C:去程单高",
            price: 959,
          },
        ],
      },
      {
        id: 4,
        img: require("@/assets/img/jingdian/jd04.jpg"),
        title: "苏州+普陀山+千岛湖4日3晚跟团游",
        price: 1587,
        desc: "祈福游【限时特惠 每人立减-50】宿1晚宁波市区酒店+1晚普陀山岛上海边农家【赠2早3正餐+含上行缆车 登佛顶山 俯瞰普陀山全景】纯玩0购物 许愿还愿 祈福游 海边农家步行至沙滩5分钟可到",
        bigImg: require("@/assets/img/jingdian/jd04-01.jpg"),
        line: [
          {
            title: "A:往返双高",
            price: 1319,
          },
          {
            title: "B:往返双飞",
            price: 1099,
          },
          {
            title: "C:去程单高",
            price: 1099,
          },
        ],
      },
    ],
    hotelList:[
      {
        id: 5,
        img: require("@/assets/img/hotel/ht01.jpg"),
        title: "上海虹桥九亭天地美居酒店",
        price: 317,
      },
      {
        id: 6,
        img: require("@/assets/img/hotel/ht02.jpg"),
        title: "上海外滩豫园美居酒店",
        price: 1038,
      },
      {
        id: 7,
        img: require("@/assets/img/hotel/ht03.jpg"),
        title: "北京朝阳门工体美居酒店",
        price: 881,
      },
      {
        id: 8,
        img: require("@/assets/img/hotel/ht04.jpg"),
        title: "北京后海南锣鼓巷CitiGO欢阁酒店",
        price: 705,
      }
    ]
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
