import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login"),
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/home/Home"),
  },
  {
    path: "/comment",
    name: "comment",
    component: () => import("../views/comment/CommentPage"),
  },
  //搜索页面

  {
    path: "/detail",
    name: "detail",
    component: () => import("../views/scenicSpot/Detail"),
    props: (route) => ({ id: route.query.id }),
  },
  {
    path: "/hotel",
    name: "hotel",
    component: () => import("../views/hotel/HotelDetail"),
    props: (route) => ({ id: route.query.id }),
  },
  {
    path: "/person",
    name: "person",
    component: () => import("../views/person/PersonPage"),
  },
  //定制页面 
  {
    path: "/diy",
    name: "diy",
    component: () => import("../views/diy/Diy"),
    props: (route) => ({param:route.query.param}),
  },

    //返回修改页面
    {
      path: "/update",
      name: "update",
      component: () => import("../views/update/Update"),
      props: (route) => ({param:route.query.param}),
    },

    {
      path: "/query",
      name: "query",
      component: () => import("../views/scenicSpot/Modal"),
      props: (route) => ({param:route.query.param}),
    },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 如果存在 savedPosition，则滚动到保存的位置
    if (savedPosition) {
      return savedPosition;
    } else {
      // 否则滚动到页面顶部
      return { top: 0 };
    }
  },
})

export default router
